<template>
	<div>
		<div class="mx-4">
			<div class="overflow-y" style="max-height: calc(100vh - 250px)">
				<v-layout class="p-4 border-bottom-light-grey min-height-57px">
					<v-flex class="font-level-3-bold my-auto">
						<span class="detail-svg-icon mr-2">
							<!--begin::Svg Icon-->
							<inline-svg :src="$assetURL('media/custom-svg/signature.svg')" />
							<!--end::Svg Icon-->
						</span>
						Change Signature
					</v-flex>
					<v-flex class="font-level-3-bold my-auto float-right">
						<v-btn
							v-if="showCanvas"
							:loading="pageLoading"
							class="white--text mr-2 float-right"
							depressed
							color="blue darken-4"
							tile
							:disabled="pageLoading"
							@click="submit"
						>
							Update Signature
						</v-btn>
						<v-btn
							v-else
							:loading="pageLoading"
							class="white--text mr-2 float-right"
							depressed
							color="blue darken-4"
							tile
							:disabled="pageLoading"
							v-on:click="(showCanvas = true), initSignature()"
						>
							Create Signature
						</v-btn>
					</v-flex>
				</v-layout>
			</div>
		</div>
		<div v-if="!pageLoading">
			<div v-if="showCanvas" class="d-flex" style="min-width: 100vw">
				<div
					class="d-flex justify-center align-end"
					style="max-height: calc(100vh - 206px),min-height: 60vh;max-width:50vw;"
				>
					<div
						ref="signature_div"
						class="custom-border-grey-dashed mt-2"
						style="min-width: 100%,min-height:450px"
					>
						<canvas ref="cc_signature"></canvas>
					</div>
					<v-tooltip top>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								v-bind="attrs"
								v-on="on"
								small
								v-on:click="clear_signature()"
								class="ml-2"
								text
								outlined
								><v-icon>mdi-close</v-icon></v-btn
							>
						</template>
						<span>Clear Signature</span>
					</v-tooltip>
				</div>
			</div>
			<div
				v-if="!showCanvas && memberSignature"
				class="d-flex justify-center align-end mt-2"
				style="max-width: 50vw"
			>
				<v-img
					:src="memberSignature"
					max-height="auto"
					max-width="auto"
					style="border: 1px solid grey"
				/>
				<!-- <v-tooltip top>
					<template v-slot:activator="{ on, attrs }">
						<v-btn v-bind="attrs" v-on="on" small v-on:click="showCanvas = true, initSignature()" class="ml-2"
							text outlined><v-icon>mdi-close</v-icon></v-btn>
					</template>
					<span>Create new Signature Signature</span>
				</v-tooltip> -->
			</div>
		</div>
		<div v-else class="mx-20 my-40 text-center">
			<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
		</div>
	</div>
</template>
<script>
import SignaturePad from "signature_pad";
import { SET_MESSAGE, SET_ERROR } from "@/core/services/store/common.module";
import { updateSignature, getUserSignature } from "@/core/lib/member.lib";
// import ImageTemplate from "@/view/components/Image";
import ListingMixin from "@/core/mixins/listing.mixin.js";

export default {
	name: "signature",
	mixins: [ListingMixin],
	data() {
		return {
			signature: null,
			memberSignature: null,
			showCanvas: false,
		};
	},
	props: {
		member: {
			type: Object,
			default: () => {},
		},
	},
	// components: {
	// 	ImageTemplate
	// },
	methods: {
		initSignature() {
			const _this = this;
			_this.$nextTick(() => {
				let ccanvas = _this.$refs["cc_signature"];
				let ccanvasDiv = _this.$refs["signature_div"];
				ccanvas.setAttribute("width", 800);
				ccanvasDiv.setAttribute("style", "width:" + "800px");
				_this.signature = new SignaturePad(ccanvas);
			});
		},
		clear_signature() {
			const signature_pad = this.signature;
			if (signature_pad) {
				signature_pad.clear();
			}
		},
		async submit() {
			const _this = this;

			if (_this.signature.isEmpty()) {
				_this.$store.commit(SET_ERROR, [{ model: true, message: `Kindly sign your signature.` }]);
				return false;
			}
			try {
				_this.pageLoading = true;
				const formData = {
					files: [
						{
							name: "signature",
							suffix: "jpg",
							base64: _this.signature.toDataURL(),
						},
					],
				};
				await updateSignature(this.member.uuid, formData);
				_this.$store.commit(SET_MESSAGE, [
					{ model: true, message: "Success ! Signature has been create updated." },
				]);
				await this.getSignature();
				_this.showCanvas = false;
			} catch (error) {
				_this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				this.showCanvas = true;
				// _this.$nextTick(() => {
				this.initSignature();
				// })
			} finally {
				_this.pageLoading = false;
			}
		},
		async getSignature() {
			const _this = this;
			_this.pageLoading = true;
			try {
				const data = await getUserSignature(this.member.uuid);
				this.memberSignature = data.signature + "?" + new Date().getTime();
			} catch (error) {
				_this.$store.commit(SET_MESSAGE, [
					{ model: true, message: "Success ! Signature is Updated has been create." },
				]);
			} finally {
				_this.pageLoading = false;
			}
		},
	},
	async mounted() {
		if (this.showCanvas) {
			this.initSignature();
		}
		await this.getSignature();
	},
};
</script>
